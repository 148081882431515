.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: @header-top-bg;
  box-shadow: 0 2px 3px rgba(51, 51, 51, 0.15);
  & > div {
    position: relative;
    max-width: 728px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
  @media @md {
    display: block;
  }
  @media @md-strict {
    background-color: @header-menu-bg;
    .sol-mobile-logo {
      display: none;
    }
  }
  .sol-mobile-logo {
    float: left;
    margin-left: 15px;
    margin-top: 5px;
    height: 50px;
  }
  .sol-hamburger-wrapper {
    .focus-outline;
    width: 55px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: @color-1;
    border: 0;
    text-align: center;
    padding-top: 32px;
    & .sol-hamburger {
      width: 35px;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -18px;
      & > span {
        display: block;
        width: 35px;
        height: 3px;
        border-radius: 2px;
        background-color: @white;
        transition: transform 500ms;
      }
      & .sol-hamburger-middle {
        margin-top: 6px;
        margin-bottom: 6px;
        transition: width 500ms;
      }
      &.sol-active {
        .sol-hamburger-top {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
          width: 37px;
        }
        .sol-hamburger-middle {
          width: 0;
        }
        .sol-hamburger-bottom {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
          width: 37px;
        }
      }
      @media @sm {
        right: 10px;
      }
    }
    & .sol-hamburger-text {
      color: @white;
      font-size: 12px;
      font-weight: bold;
      font-family: @main-font;
    }
  }
}
