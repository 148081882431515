.sol-print {
  color: @print-btn-text;
  font-size: 14px;
  font-weight: 600;
  float: right;
  & > span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & i.fa {
    margin-right: 5px;
  }
}
