.clearfix() {
  &:after,
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.no-list-style() {
  list-style: none;
  padding: 0;
  margin: 0;
}
.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}
.no-outline-focus() {
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}
.no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
}
.icon(@icon; @size:inherit; @display:inline-block; @position: none) {
  .fa;
  display: @display;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}
.custom-icon(@custom-icon; @size: inherit; @position: after) {
  .hf;
  display: inline-block;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @custom-icon;
    }
  }
}
.transition( @property; @duration:200ms; @style: ease) {
  -webkit-transition-property: @property;
  transition-property: @property;
  -webkit-transition-duration: @duration;
  transition-duration: @duration;
  -webkit-transition-timing-function: @style;
  transition-timing-function: @style;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.focus-outline() {
  &:focus,
  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid @focus-border;
    z-index: 10;
  }
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}
.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}
