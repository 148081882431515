.sol-top-nav {
  .sol-clearfix;
  display: flex;
  vertical-align: top;
  flex: 1 auto;
  align-items: center;
  position: absolute;
  right: 45px;
  bottom: 0;

  &-item {
    .focus-outline;
    float: left;
    padding: 25px 0 10px;
    //border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: @header-menu-text;
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 400;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
    text-decoration: none;
    //max-width: 125px;
    //height: 75px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    border-bottom: 4px solid transparent;
    .transition(background-color);
    &:first-child {
      //border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:last-child {
      margin-right: 12px;
    }
    &:hover {
      border-color: shade(@header-menu-bg,10%);
    }
    &.sol-active {
      font-weight: 700;
      border-color: @color-2;
    }
  }
  @media @md {
    display: none;
  }
}
