.sol-shortcut-container {
  background-color: @footer-top-bg;
  padding: 20px 20px 0;
}
.sol-shortcut {
  .sol-clearfix;
  margin-left: -10px;
  margin-right: -10px;
  @media @sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  a {
    text-decoration: none;
    max-width: 150px;
    display: inline-block;
  }
  &-item {
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    @media @sm {
      width: 33%!important;
    }
    @media @xs {
      width: 49%!important;
    }
    & i.fa {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto 10px;
      border: 2px solid @footer-shortcut-text;
      border-radius: 50%;
      background-color: @footer-shortcut-bg;
      color: @footer-shortcut-text;
      font-size: 21px;
      text-align: center;
      line-height: 54px;
      .transition(~  'background-color, color');
    }
    & a > span {
      color: @footer-shortcut-text;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      .transition(color);
    }
    & a:focus,
    & a:hover {
      i.fa {
        background-color: @footer-shortcut-icon-bg-hover;
        color: @footer-shortcut-icon-hover;
      }
      a > span {
        color: @footer-shortcut-text-hover;
      }
    }
  }
}
