  body {
    font-family: @main-font;
    color: @general-text;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    background-color: @body-bg;
    overflow-x: hidden;
  }
  
  p,
  p.font-normal,
  .font-normal,
  p.font-description,
  .font-description {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: @main-font;
    color: @normal-text;
    font-size: 16px;
    line-height: 1.7em;
    font-weight: 400;
    @media @sm {
      font-size: 15px;
    }
    a&,
    a {
      color: @normal-link;
      text-decoration: underline;
      &:hover {
        color: @normal-link-hover;
      }
    }
  }
  
  p.font-description {
    color: @desc-text;
    font-weight: 700;
    a&,
    a {
      color: @desc-link;     
      &:hover {
        color: @desc-link-hover;
      }
    }
  }  
  
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-family: @main-font;
    color: @h1-text;
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: 34px;
    line-height: 1.4em;
    font-weight: 300;
    @media @md {
      font-size: 31px;
      line-height: 25px;
    }
    @media @sm {
      font-size: 26px;
    }
    @media @xs {
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 26px;
    }
  }
  
  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-family: @main-font;
    color: @h2-text;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 800;
    @media @xs {
      font-size: 18px;
      line-height: 29px;
    }
  }
  
  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-family: @main-font;
    color: @h3-text;
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 800;
    @media @xs {
      font-size: 14px;
    }
  }
  
  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-family: @main-font;
    color: @h4-text;
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.2em;
    font-weight: 800;
    @media @xs {
      font-size: 12px;
    }
  }
  
  p.font-quote,
  .font-quote {
    position: relative;
    font-family: @main-font;
    color: @quote-text;
    font-size: 22px;
    line-height: 29px;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 55px;
    @media @md{
      padding-left: 35px;
      font-size: 20px;
      line-height: 27px;
    }
    @media @sm{
      font-size: 18px;
      line-height: 25px;
    }
    @media @xs{
      padding-left: 30px;
    }
    &::before {
      .icon(@fa-var-quote-right, 20px);
      position: absolute;
      left: 20px;
      top: 0px;
      opacity: 0.3;
      line-height: 35px;
      @media @md{
        left: 5px;
        line-height: 33px;
      }
      @media @xs{
        left: 5px;
        line-height: 31px;
      }
    }

  }
  
  ol,
  ul {
    &.sol-bullet-list,
    &.sol-numbered-list {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 0px;
      list-style: none !important;
      & li {
        position: relative;
        margin-bottom: 7px;
        padding-left: 15px;
        &::before {
          position: absolute;
          left: 0;
          top: 4px;         
          display: inline-block;
          font-weight: 700;        
        }
      }
    }
  }
  
  .sol-bullet-list li::before {
    .icon(@fa-var-angle-right, 16px);
    color: @list-icon;
  }
  
  ol,
  ul {
    &.sol-numbered-list {
      & li {
        counter-increment: step-counter;
        padding-left: 20px;
        &::before {
          content: counter(step-counter) '.';
          color: @list-number;
          font-size: 14px;
          text-align: center;    
          top: 0;     
        }
      }
    }
  }
