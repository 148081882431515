header {
  position: relative;
  z-index: 50;
  background-color: @header-top-bg;

  .sol-top-search {
    position: relative;
    .transition(~  'background-color, height');
    background-color: @search-top-bg;
    height: 0;
    overflow: hidden;
    display: none;

    &.sol-active {
      height: 115px;
      background-color: @search-top-bg-active;
      @media @md {
        height: 100px;
      }
      @media @sm {
        height: 80px;
      }
    }

    & form {
      position: relative;
      display: block;
      width: 58%;
      margin: 35px auto;
      @media @md {
        width: 70%;
        margin-top: 25px;
        margin-bottom: 25px;
      }
      @media @sm {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @media @xs {
        width: 90%;
      }
    }

    &-close {
      .no-appearance;
      .focus-outline;
      .transition(color);
      position: absolute;
      right: -74px;
      top: 9px;
      color: @color-1;
      text-decoration: underline;
      cursor: pointer;
      @media @md {
        display: none;
      }
      &:hover {
        color: @search-top-close;
      }
    }
  }

  .sol-top {
    padding: 15px 45px;
    background-color: @header-top-bg;
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);
    @media @sm {
      text-align: center;
      background-color: @header-top-mobile-bg;
      padding-top: 13px;
      padding-bottom: 8px;
    }
    @media @sm {
      padding-left: 7px;
      padding-right: 7px;
    }
    > .sv-grid-main {
      position: relative;
    }
    .sol-top-logo {
      & > img {
        height: 85px;
      }
      @media @sm {
        display: none;
      }
    }
  }

  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: center;
    @media @md {
      display: none;
    }
  }
}

.sol-to-content {
  position: absolute;
  right: -5000px;
  top: -3px;
  width: 135px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: @color-1;
  font-size: 14px;
  color: @white;
  &:focus {
    right: 100%;
    @media @sm {
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
