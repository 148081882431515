.sol-subpage-wrapper {
  .sol-clearfix;
  > .sol-left {
    width: 65%;
    float: left;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  > .sol-right {
    width: 30%;
    float: right;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}
