
.sol-popular-services {  
  & a {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 20px 10px 20px 40px;
    border-bottom: 1px solid @services-border;
    color: @services-text;
    font-size: 15px;
    line-height: 20px;
    .transition(color);
    &:hover {
      color: @services-text-hover;
      text-decoration: underline;
    }
    & i.fa {
      font-size: 14px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      color: @services-icon;
      border: 1px solid @services-icon;
      border-radius: 50%;
      text-align: center;
      padding-left: 1px;
      padding-top: 1px;
      position: absolute;
      left: 9px;
      top: 50%;
      margin-top: -9px;
    }
  }
}
