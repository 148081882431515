.button() {
  .focus-outline;
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 8px 25px;
  color: @button-text;
  border-radius: 3px;
  background-color: @button-bg;
  //box-shadow: inset 0 -2px 0 0 @button-box-shadow;
  .transition(~  'background-color, color');
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-family: @main-font;
  border: 0;
  &:hover {
    background-color: @button-bg-hover;
    color: @button-text-hover;
  }
  &:active {
    top: 1px;
    box-shadow: inset 0 -1px 0 0 @button-box-shadow;
  }
  & i.fa {
    margin-right: 10px;
    margin-left: -5px;
    font-weight: 400;
  }
}
.button-small() {
  padding: 7px 25px 8px;
  color: @button-small-text;
  background-color: @button-small-bg;
  box-shadow: none;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
  &:hover {
    background-color: @button-small-bg-hover;
    color: @button-small-text-hover;
  }
  &:active {
    top: 0;
    box-shadow: none;
  }
}
