.sol-startpage-wrapper {
  .sol-top {
    position: relative;
    &:after {
      content: '';
      background-image: url('@{sol-graphics-path}/Orter.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 13px;
      width: 355px;
      display: block;
      position: relative;
      bottom: -25px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  & .sol-startpage-content-bottom,
  & .sol-startpage-content-top {
    & > div:first-child {
      padding-right: 15px;
      @media @md {
        padding-right: 10px;
      }
    }
    & > div:last-child {
      padding-left: 15px;
      @media @md {
        padding-left: 10px;
      }
    }
    .sol-startpage-push {
      text-align: center;
      a {
        text-decoration: none;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
  & .sol-startpage-content-bottom {
    margin-top: 35px;
    @media @md {
      margin-top: 15px;
    }
    @media @sm {
      margin-top: 0;
    }
    h2.font-heading-2 {
      margin-top: 0;
      margin-bottom: 15px;
    }
    .sol-left {
      h2.font-heading-2 {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
    .sol-right {
      padding-top: 33px;
      @media @sm {
        padding-top: 0;
      }
    }
  }
}
