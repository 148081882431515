@hf-icon-Cloud: "\e900";
@hf-icon-Arrow: "\e901";
@hf-icon-Cable: "\e902";
@hf-icon-Check: "\e903";
@hf-icon-Cry: "\e904";
@hf-icon-Doc: "\e905";
@hf-icon-Sunglasses: "\e906";
@hf-icon-Headset: "\e907";
@hf-icon-Heart: "\e908";
@hf-icon-Info: "\e909";
@hf-icon-Light: "\e90a";
@hf-icon-Like: "\e90b";
@hf-icon-Map: "\e90c";
@hf-icon-Maze: "\e90d";
@hf-icon-Phone: "\e90e";
@hf-icon-Pin: "\e90f";
@hf-icon-Screen: "\e910";
@hf-icon-Search: "\e911";
@hf-icon-Smile: "\e912";
@hf-icon-Speechbubble: "\e913";
@hf-icon-Tablet: "\e914";
@hf-icon-Timeglass: "\e915";
@hf-icon-Warning: "\e916";
@hf-icon-Wifi: "\e917";
@hf-icon-Club: "\e918";
@hf-icon-Book: "\e919";
@hf-icon-Glasses: "\e91a";
@hf-icon-Heart-add: "\e91b";
@hf-icon-Apple: "\e91c";
@hf-icon-Decoration: "\e91d";
@hf-icon-Tree: "\e91e";
@hf-icon-Group: "\e91f";
@hf-icon-Hands: "\e920";
@hf-icon-Home: "\e921";
@hf-icon-Question: "\e922";

@font-face {
  font-family: 'hoglandet';
  src:
    url('/webdav/files/System/resources/fonts/hoglandet/hoglandet.ttf?osk21s') format('truetype'),
    url('/webdav/files/System/resources/fonts/hoglandet/hoglandet.woff?osk21s') format('woff'),
    url('/webdav/files/System/resources/fonts/hoglandet/hoglandet.svg?osk21s#hoglandet') format('svg');
  font-weight: normal;
  font-style: normal;
}

.hf {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hoglandet' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hf-icon-Cloud {
  &:before {
    content: @hf-icon-Cloud;
  }
}
.hf-icon-Arrow {
  &:before {
    content: @hf-icon-Arrow;
  }
}
.hf-icon-Cable {
  &:before {
    content: @hf-icon-Cable;
  }
}
.hf-icon-Check {
  &:before {
    content: @hf-icon-Check;
  }
}
.hf-icon-Cry {
  &:before {
    content: @hf-icon-Cry;
  }
}
.hf-icon-Doc {
  &:before {
    content: @hf-icon-Doc;
  }
}
.hf-icon-Sunglasses {
  &:before {
    content: @hf-icon-Sunglasses;
  }
}
.hf-icon-Headset {
  &:before {
    content: @hf-icon-Headset;
  }
}
.hf-icon-Heart {
  &:before {
    content: @hf-icon-Heart;
  }
}
.hf-icon-Info {
  &:before {
    content: @hf-icon-Info;
  }
}
.hf-icon-Light {
  &:before {
    content: @hf-icon-Light;
  }
}
.hf-icon-Like {
  &:before {
    content: @hf-icon-Like;
  }
}
.hf-icon-Map {
  &:before {
    content: @hf-icon-Map;
  }
}
.hf-icon-Maze {
  &:before {
    content: @hf-icon-Maze;
  }
}
.hf-icon-Phone {
  &:before {
    content: @hf-icon-Phone;
  }
}
.hf-icon-Pin {
  &:before {
    content: @hf-icon-Pin;
  }
}
.hf-icon-Screen {
  &:before {
    content: @hf-icon-Screen;
  }
}
.hf-icon-Search {
  &:before {
    content: @hf-icon-Search;
  }
}
.hf-icon-Smile {
  &:before {
    content: @hf-icon-Smile;
  }
}
.hf-icon-Speechbubble {
  &:before {
    content: @hf-icon-Speechbubble;
  }
}
.hf-icon-Tablet {
  &:before {
    content: @hf-icon-Tablet;
  }
}
.hf-icon-Timeglass {
  &:before {
    content: @hf-icon-Timeglass;
  }
}
.hf-icon-Warning {
  &:before {
    content: @hf-icon-Warning;
  }
}
.hf-icon-Wifi {
  &:before {
    content: @hf-icon-Wifi;
  }
}
.hf-icon-Club {
  &:before {
    content: @hf-icon-Club;
  }
}
.hf-icon-Book {
  &:before {
    content: @hf-icon-Book;
  }
}
.hf-icon-Glasses {
  &:before {
    content: @hf-icon-Glasses;
  }
}
.hf-icon-Heart-add {
  &:before {
    content: @hf-icon-Heart-add;
  }
}
.hf-icon-Apple {
  &:before {
    content: @hf-icon-Apple;
  }
}
.hf-icon-Decoration {
  &:before {
    content: @hf-icon-Decoration;
  }
}
.hf-icon-Tree {
  &:before {
    content: @hf-icon-Tree;
  }
}
.hf-icon-Group {
  &:before {
    content: @hf-icon-Group;
  }
}
.hf-icon-Hands {
  &:before {
    content: @hf-icon-Hands;
  }
}
.hf-icon-Home {
  &:before {
    content: @hf-icon-Home;
  }
}
.hf-icon-Question {
  &:before {
    content: @hf-icon-Question;
  }
}

